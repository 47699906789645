.qna-list::-webkit-scrollbar {
  width: 0px;
}

textarea {
  resize: none;
}
.sticky-question {
  position: sticky;
  position: -webkit-sticky;
  border-radius: 12px;
  transition: border-radius 2s;
}
.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.summary-sklton {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.sklton-line {
  height: 9px;
  background-color: #f1f2f4;
  border-radius: 12px;
}
.form-container {
  background-color: white;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .form-wrapper {
    background-color: gainsboro;
    padding: 20px 60px;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 19px;
    .form-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3px;
      .logo {
        font-size: 33px;
      }
      .page-title {
        font-size: 12px;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 12px;
      min-width: 300px;
      input {
        padding: 12px;
        border-radius: 9px;
        border: none;
      }
      .login-action-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      button {
        height: 33px;
        padding: 7px 9px;
        cursor: pointer;
      }
      button.teritiary-button {
        background-color: transparent;
        border: none;
        color: black;
        padding: 0px;
      }
      &:active {
        border: 1px solid #218bff;
      }
    }
  }
}

.feed-container {
  height: calc(100% - (70px + 18px));
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  background-color: white;
  position: sticky;
  position: -webkit-sticky;
  .feed-home-container {
    width: calc(100% - 320px);
    min-height: calc(100vh - 100px);
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding-top: 60px;
    margin-bottom: 165px;
    position: sticky;
    position: -webkit-sticky;
  }
  .navbar {
    box-sizing: border-box;
    border-bottom: 1px solid #f0f0f0;
    padding: 0px 3%;
    width: calc(100% - 6px);
    color: white;
    position: fixed;
    position: -webkit-sticky;
    z-index: 99;
    top: 0;
    background-color: #fafafa;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .leading-action-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      h4 {
        color: black;
      }
      .logo {
        color: black;
        img {
          width: 33px;
          height: 33px;
        }
      }
    }
  }
}

.question-info-leading-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
}

.circle-avatar-container {
  height: 33px;
  width: 33px;
  border-radius: 24px;
  background-color: white;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  object-fit: cover;
  overflow: hidden;
  img {
    width: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}

.question-tile-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .question-info-text {
    color: #232323;
    margin: 0;
    font-family: "Inter";
    font-weight: 500;
    font-size: 12px;
  }
}

.read-more {
  border: 1px solid #f0f0f0;
  background-color: #fafafa;
  border-radius: 50px;
  box-shadow: 0px 0px 0.75px 0px #d1d7dd;
  width: max-content;
  height: max-content;
  padding: 7px 9px;
  font-weight: 500;
  color: black;
  margin: 0;
  margin-top: 7px;
  border-radius: 32px;
  font-size: 12px;
  font-weight: 600;
  align-self: flex-end;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 9px 3px #d1d7dd;
    transition: box-shadow 0.75s;
  }
}
.question-tile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
  top: 27px;
}

.qna-tile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  position: sticky;
  position: -webkit-sticky;
  // padding-top: 42px;
  // top: 43px;
}

.end-of-list {
  height: 50px;
  width: 100px;
}
.input-field-container {
  transition: all 0.15s;
  box-shadow: 0px 0px 9px 0px #d1d7dd;
  background-color: white;
  padding: 12px 18px;
  min-width: 333px;
  max-width: 771px;
  border-radius: 12px;
  position: fixed;
  bottom: 12px;
  gap: 7px;
  // overflow: auto;
  .input-field {
    padding: 0;
    width: 100%;
    min-height: 42px;
    border: none;
    outline: none;
    // background-color: white;
    // font-size: 15px;
    justify-content: start;
    align-items: start;
    font-family: "Inter";
    font-weight: 500;
    line-height: 21px;
  }

  textarea::placeholder {
    color: lightgrey;
    align-items: start;
  }
  .trailing-input-action-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .send-button {
      height: 39px;
      width: 39px;
      cursor: pointer;
      border: none;
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-radius: 51px;
      box-shadow: 0px 0px 3px 0px #d1d7dd;
      .feather-icon {
        fill: black;
        color: black;
      }
      &:hover {
        box-shadow: 0px 0px 9px 3px #d1d7dd;
        transition: box-shadow 0.75s;
      }
    }
    .clear-button {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      width: 21px;
      height: 21px;
      padding: 0;
      margin: 0;
      align-items: center;
      justify-content: center;
      background-color: white;
      .feather-icon {
        color: black;
        stroke-width: 1.74px;
      }
    }
  }
}

.input-field-container:focus-within {
  box-shadow: -3px -3px 39px 3px #3359813e;
  // border: 1px solid #a7b4c365;
  border-radius: 15px;
  transition: all 0.15s;
}
